import React from 'react'
import styled from '@emotion/styled'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from './Typography'

const MainMessage = props => {
  const { title, body, ...others } = props
  return (
    <Root {...others}>
      <Container>
        <Title {...others} variant="h1" component="h2">
          {title}
        </Title>
        <Body {...others} variant="h4" component="p">
          {body}
        </Body>
      </Container>
    </Root>
  )
}

const Root = styled.div`
  padding: 80px 0;
  @media ${mq.and(mobile)} {
    padding: 64px 0;
  }
`

const Container = styled.div`
  margin-top: -40px;
  @media ${mq.and(mobile)} {
    margin-top: -28px;
  }
`

const Title = styled(Typography)`
  margin-top: 40px;
  text-align: center;
  @media ${mq.and(mobile)} {
    margin-top: 28px;
  }
`

const Body = styled(Typography)`
  margin: 40px auto 0;
  max-width: 768px;
  text-align: center;
  @media ${mq.and(mobile)} {
    margin-top: 28px;
    text-align: left;
    br {
      display: none;
    }
  }
`

export default MainMessage
